@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root{
  /* --primary-light-red: #eedcdc; */
  --primary-white: #ffffff;
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-test: #3a040b;


}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*---------------------------Headers--------------------------------------------*/
.main-info{
  display:flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
  font-weight: 600;
}

.main-info h1{
  color: var(--primary-white);
  text-transform: uppercase;
  font-weight: 600;
}

.typed-text {
  font-size:2rem;
  color: var(--primary-white)
}

.btn-main-offer{
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin:2rem;
  text-decoration: none;
}

.btn-main-offer:hover{
  text-decoration: none ;
  background-color: var(--primary-hover-red) ;
  color: var(--primary-white);
  transition: .2s ease-in-out;

}

@media(max-width:768px) {
    .main-info h1{
      font-size: 1.7rem !important;
    }
  .typed-text {
    font-size:1.3rem !important;
  }
}

@media(max-height:376px) {
  .main-info h1{
    margin-top: auto;
    font-family: "Roboto Condensed", sans-serif;
  }
}

