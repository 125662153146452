@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root{
  --primary-scarlet:rgb(53, 12, 21);
  --primary-white:#ffffff;
}


.form-signin {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background:rgb(240, 233, 233);
    /* background-image: url('./images_ludis/ludis-background.jpg'); */
    background-image: url('https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/ludis-background_wxofKklP5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648731787562');
    background-size: cover;
    opacity: 0.8;
    height: 65vh;
  }

  .form-signin:hover{
    opacity:0.95
  }
  
  .form-signin .checkbox {
    font-weight: 400;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    /* width: 400px; */
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    /* width: 400px; */
    
  }
  

  .img-logo2 {   
    /*margin:auto;*/
    background-color: rgb(219, 214, 224);
    background-image: url(/static/media/s4b-logo-long.b0bf9b61.png);
    height: 10vh;
    

    /*margin-inline-start: 170px;*/
  }

  .modal-content {
    color: rgb(53, 12, 21);
    color: var(--primary-scarlet);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 900;
  }

.headerLogin {
  margin-top: auto;
}

.modal-footer{
  display:flex !important;
  flex-direction: row;
  justify-content: space-between !important;
}




  /*--------------------------------------MEDIA---------------------------------------------------------------*/

  /* @media(min-width: 450px){ */
    @media(min-width: 668px){

    
  .form-signin input[type="email"] {
    width: 400px;
  }
  
  .form-signin input[type="password"] {
    width: 400px;  
  }


  }

  @media(max-width: 668px){
    
    .img-logo2 {
      display: none;
    }  

    .form-signin input[type="email"] {
      height: 50px;
      font-size:15px;
    }
    
    .form-signin input[type="password"] {
      height: 50px;  
      font-size:15px;
    }

    .form-signin button[type="submit"]{
      height:40px;
      font-size:15px;
    }

    .headerLogin {
      font-size: 18px;
    }

    }

  @media(max-width:455px){
    .modal-footer{
      flex-direction: column-reverse;
    }
  }    
:root{
  /* --primary-light-red: #eedcdc; */
  --primary-white: #ffffff;
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-test: #3a040b;


}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*---------------------------Headers--------------------------------------------*/
.main-info{
  display:flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
  font-weight: 600;
}

.main-info h1{
  color: #ffffff;
  color: var(--primary-white);
  text-transform: uppercase;
  font-weight: 600;
}

.typed-text {
  font-size:2rem;
  color: #ffffff;
  color: var(--primary-white)
}

.btn-main-offer{
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #ffffff;
  color: var(--primary-white);
  margin:2rem;
  text-decoration: none;
}

.btn-main-offer:hover{
  text-decoration: none ;
  background-color: #f33501;
  background-color: var(--primary-hover-red) ;
  color: #ffffff;
  color: var(--primary-white);
  transition: .2s ease-in-out;

}

@media(max-width:768px) {
    .main-info h1{
      font-size: 1.7rem !important;
    }
  .typed-text {
    font-size:1.3rem !important;
  }
}

@media(max-height:376px) {
  .main-info h1{
    margin-top: auto;
    font-family: "Roboto Condensed", sans-serif;
  }
}


html,
body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.home-header {
  /*background: url("images/selector_bg.jpg") center center;*/
  /* background: url("images_ludis/ludis-background-2.jpg") center center; */
  /* background: url("images_ludis/ludis-background-3.png") center center; */
  background: url("https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/ludis-background-3_Pt426dEDJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648723519968") center center;
  min-height: 100vh;
  width: 100%;
}

header .btn.btn-primary {
  padding: 15px 30px;
}

header nav a {
  display: block;
  color: white;
  text-decoration: none;
  transition: 0.2s all ease-in;
}

header nav i {
  font-size: 40px;
}

header nav a:hover {
  color: #ffc107;
}

.main-samples {
  background: url(/static/media/demos_bg.65a6ebde.jpg) center center;
  /* background: url("images_ludis/ludis-background.jpg") center center; */
  background-attachment: fixed;
  min-height: 100vh;
}

section#modules {
  background: white;
}

section#presentation {
  background: linear-gradient(
    90deg,
    rgb(78, 23, 33) 28%,
    rgb(219, 194, 204) 100%
  );
  padding: 3rem;
}

section#mobile {
  /* background: url("images/s_mobile.jpg") center center; */
  /* background: url("images_ludis/golf-green-background.jpg") center center; */
  background: url("https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/golf-green-background_O33azUYeA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648723654924") center center;
  background-attachment: fixed;
  min-height: 100vh;
}

footer {
  background: #151515;
  color: #b8b0b0;
  padding: 20px;
}

/*added by LH*/
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  /*--------------------------------------HOME PAGE ---------------------------*/

  .modal-container{
    height: 20vh !important;
    overflow: hidden;
  }

  .s4b-logo{
    border-radius: 50%;
    height: 4vh;
    margin: 5px;
  }

  .form-footer{
    display:flex;
    flex-direction: row;
    align-items: center;
  }

  .login-button{
    background: none;
    color:white;
    border: none;
  }


  /*----------------------------------Carousel @ presentation section ------------*/

  .carouselPics{
    display:flex;
    height:40vh;
    /* margin:auto; */
    margin: 150px auto;
  }






