@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');


:root{
  --primary-scarlet:rgb(53, 12, 21);
  --primary-white:#ffffff;
}


.form-signin {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background:rgb(240, 233, 233);
    /* background-image: url('./images_ludis/ludis-background.jpg'); */
    background-image: url('https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/ludis-background_wxofKklP5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648731787562');
    background-size: cover;
    opacity: 0.8;
    height: 65vh;
  }

  .form-signin:hover{
    opacity:0.95
  }
  
  .form-signin .checkbox {
    font-weight: 400;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    /* width: 400px; */
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    /* width: 400px; */
    
  }
  

  .img-logo2 {   
    /*margin:auto;*/
    background-color: rgb(219, 214, 224);
    background-image: url('./images/s4b-logo-long.png');
    height: 10vh;
    

    /*margin-inline-start: 170px;*/
  }

  .modal-content {
    color: var(--primary-scarlet);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 900;
  }

.headerLogin {
  margin-top: auto;
}

.modal-footer{
  display:flex !important;
  flex-direction: row;
  justify-content: space-between !important;
}




  /*--------------------------------------MEDIA---------------------------------------------------------------*/

  /* @media(min-width: 450px){ */
    @media(min-width: 668px){

    
  .form-signin input[type="email"] {
    width: 400px;
  }
  
  .form-signin input[type="password"] {
    width: 400px;  
  }


  }

  @media(max-width: 668px){
    
    .img-logo2 {
      display: none;
    }  

    .form-signin input[type="email"] {
      height: 50px;
      font-size:15px;
    }
    
    .form-signin input[type="password"] {
      height: 50px;  
      font-size:15px;
    }

    .form-signin button[type="submit"]{
      height:40px;
      font-size:15px;
    }

    .headerLogin {
      font-size: 18px;
    }

    }

  @media(max-width:455px){
    .modal-footer{
      flex-direction: column-reverse;
    }
  }    