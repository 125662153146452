html,
body {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.home-header {
  /*background: url("images/selector_bg.jpg") center center;*/
  /* background: url("images_ludis/ludis-background-2.jpg") center center; */
  /* background: url("images_ludis/ludis-background-3.png") center center; */
  background: url("https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/ludis-background-3_Pt426dEDJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648723519968") center center;
  min-height: 100vh;
  width: 100%;
}

header .btn.btn-primary {
  padding: 15px 30px;
}

header nav a {
  display: block;
  color: white;
  text-decoration: none;
  transition: 0.2s all ease-in;
}

header nav i {
  font-size: 40px;
}

header nav a:hover {
  color: #ffc107;
}

.main-samples {
  background: url("images/demos_bg.jpg") center center;
  /* background: url("images_ludis/ludis-background.jpg") center center; */
  background-attachment: fixed;
  min-height: 100vh;
}

section#modules {
  background: white;
}

section#presentation {
  background: linear-gradient(
    90deg,
    rgb(78, 23, 33) 28%,
    rgb(219, 194, 204) 100%
  );
  padding: 3rem;
}

section#mobile {
  /* background: url("images/s_mobile.jpg") center center; */
  /* background: url("images_ludis/golf-green-background.jpg") center center; */
  background: url("https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/golf-green-background_O33azUYeA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648723654924") center center;
  background-attachment: fixed;
  min-height: 100vh;
}

footer {
  background: #151515;
  color: #b8b0b0;
  padding: 20px;
}

/*added by LH*/
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  /*--------------------------------------HOME PAGE ---------------------------*/

  .modal-container{
    height: 20vh !important;
    overflow: hidden;
  }

  .s4b-logo{
    border-radius: 50%;
    height: 4vh;
    margin: 5px;
  }

  .form-footer{
    display:flex;
    flex-direction: row;
    align-items: center;
  }

  .login-button{
    background: none;
    color:white;
    border: none;
  }


  /*----------------------------------Carousel @ presentation section ------------*/

  .carouselPics{
    display:flex;
    height:40vh;
    /* margin:auto; */
    margin: 150px auto;
  }





